/**
 * Renders the date selection view
 */

import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

//Helpers
import { getUrlSegment, getParam } from '../../../helpers/helpers';

// Translation
import translate from '../../translate/Translate';

// Dependencies
import { endOfDay, parseISO, startOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

// Components
import DateSelect from '../../common/DateSelect';
import TimeSlots from './TimeSlots';

const SelectTime = observer(class SelectTime extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      selectedDate: null
    };
  }

  componentDidMount() {
    const { history, store, reschedule } = this.props;
    const { ScheduleStore } = store;

    // If no scheduling module has been set, send back to
    // select proctor type view
    if(!ScheduleStore.schedulingModule && !reschedule) {
      history.push('type');
    }

    // Reset the stored availability
    ScheduleStore.proctorAvailability = null;
    ScheduleStore.availabilityGroups = null;
    ScheduleStore.startDate = null;
    ScheduleStore.endDate = null;
  }

  handleDateSelect = date => {
    const { ScheduleStore, AppStore } = this.props.store;
    const dateStart = startOfDay(parseISO(date));
    const dateEnd = endOfDay(parseISO(date));

    this.setState({
      selectedDate: date
    });

    // // Store the selected date
    ScheduleStore.startDate = zonedTimeToUtc(dateStart, AppStore.timeZone).toISOString();
    ScheduleStore.endDate = zonedTimeToUtc(dateEnd, AppStore.timeZone).toISOString();

    // Get the availability
    ScheduleStore.getProctorAvailability(this.showResults);
  }

  /**
   * showResults - Smoothly scrolls the time slots into view
   */
  showResults = () => {
    const results = document.querySelector('.timeslots-wrap');

    if(results) {
      results.scrollIntoView({ behavior: 'smooth' });
    }
  }

  getOpenDates = (startDate, endDate, callback) => {
    const { history, store } = this.props;
    const { ScheduleStore } = store;
    if (getParam('session')) {
      const sessionDelay = setInterval(() => {
        if (ScheduleStore.examSession) {
          // call the get open dates function after the examSession is found
          ScheduleStore.getOpenDates(startDate, endDate, callback)
          clearInterval(sessionDelay)
        }
      }, 500);
    } else {
      // call the get open dates function
      ScheduleStore.getOpenDates(startDate, endDate, callback, () => history.push('type'))

    }
  }

  render() {
    const { reschedule, store, translation } = this.props;
    const {ScheduleStore} = store;
    const { availabilityGroups, proctorAvailability, schedulingModule, examSession, workflowModule } = ScheduleStore;

    return (
      <div className="box box--md box--solo">
        {/* If an examSession exists, we’re rescheduling and this is the first step */}
        {!reschedule && schedulingModule === 'in-person' &&
          <Link
            className="back box__back"
            to="location"
          >
            {translation.back}
          </Link>
        }

        {reschedule && examSession && workflowModule !== 'in-person' &&
          <Link
            className="back box__back"
            to={`/${getUrlSegment(1)}/appointment-details?examSid=${examSession.examSid}&sessionSid=${examSession.sid}`}
          >
            {translation.back_reschedule_remote}
          </Link>
        }

        {!reschedule && schedulingModule === 'proctoru' &&
          <Link
            className="back box__back"
            to="type"
          >
            {translation.back_remote}
          </Link>
        }

        <h1 className="box__title" tabIndex="-1">
          {translation.heading}
        </h1>

        <div className="box__section">
          <DateSelect
            {...this.props}
            id="schedule-date"
            invalidDateMsg={translation.date_invalid}
            onChange={this.handleDateSelect}
            //onRender={(startDate, endDate, callback)=>this.getOpenDates(startDate, endDate, callback)}
            //openDates={ScheduleStore.openDates}
            srOnly={true}
            inline={true}
          />
        </div>

        {availabilityGroups &&
          <TimeSlots
            {...this.props}
            selectedDate={this.state.selectedDate}
            slots={availabilityGroups}
          />
        }

        {!availabilityGroups && proctorAvailability &&
          <span className="no-results">{translation.no_results}</span>
        }
      </div>
    )
  }
})

export default translate('SelectTime')(SelectTime);