import axios from 'axios';

export let config = {
  apiUrl: 'http://localhost:8011/v1',
  // apiUrl: 'https://api.dev.smarterproctoring.com/v1',
  domain: 'http://localhost:3005',
  supportScriptUrl: 'https://help.dev.smarterservices.com/embed/in-app-widget.js',
  ldApiKey: '59edfdfd082f980b153ac148',
  ltiUrl: 'https://signon-stage.smarterservices.com',
  mapsApiKey: 'AIzaSyBXUGDThYYVJOIcfKhKiUCbkQJz8LDVvXg',
  stripeApiKey: 'pk_test_KFciYfyySlhwTkFPpDmJEDYi',
  remoteLocationSid: 'PLfbb1cd14abf644afa3f0198d0bb36340',
  spDomain: 'https://app.dev.smarterproctoring.com',
  cancellationPolicyUrl: 'https://smarterservices-pdfs.s3.amazonaws.com/DMV+CANCELLATION+and+REFUND+POLICY.pdf'
}

// Flag local development
if(!process.env.REACT_APP_ENVIRONMENT) {
  sessionStorage.setItem('local', true);
}

// Develop
if(process.env.REACT_APP_ENVIRONMENT === 'develop') {
  config.apiUrl = 'https://api.dev.smarterproctoring.com/v1';
  config.domain = 'https://dev.takemydrivingtest.com';
  config.spDomain = 'https://app.dev.smarterproctoring.com';
}

// Stage
if(process.env.REACT_APP_ENVIRONMENT === 'stage') {
  config.apiUrl = 'https://api.stage.smarterproctoring.com/v1';
  config.domain = 'https://stage.takemydrivingtest.com';
  config.spDomain = 'https://app.stage.smarterproctoring.com';
}

// QA
if(process.env.REACT_APP_ENVIRONMENT === 'qa') {
  config.apiUrl = 'https://api-qa.smarterproctoring.com/v1';
  config.domain = 'https://qa.takemydrivingtest.com';
  config.ldApiKey = '55b25a2f133767270f000092';
  config.remoteLocationSid = 'PLfbb1cd14abf644afa3f0198d0bb36340';
  config.spDomain = 'https://app-qa.smarterproctoring.com';
}

// Production
if(process.env.REACT_APP_ENVIRONMENT === 'production') {
  config.supportScriptUrl = 'https://help.smarterservices.com/embed/in-app-widget.js';
  config.apiUrl = 'https://api-b.smarterproctoring.com/v1';
  config.domain = 'https://takemydrivingtest.com';
  config.ldApiKey = '55b25a2f133767270f000092';
  config.ltiUrl = 'https://signon.smarterservices.com';
  config.stripeApiKey = 'pk_live_A0FO1fJ61RdG68kSIPf9eNlG';
  config.spDomain = 'https://app-b.smarterproctoring.com';
}

// Production B
if(process.env.REACT_APP_ENVIRONMENT === 'production-b') {
  config.supportScriptUrl = 'https://help.smarterservices.com/embed/in-app-widget.js';
  config.apiUrl = 'https://api-b.smarterproctoring.com/v1';
  config.domain = 'https://app-b.takemydrivingtest.com';
  config.ldApiKey = '55b25a2f133767270f000092';
  config.ltiUrl = 'https://signon.smarterservices.com';
  config.stripeApiKey = 'pk_live_A0FO1fJ61RdG68kSIPf9eNlG';
  config.spDomain = 'https://app-b.smarterproctoring.com';
}

export let api = axios.create({
  baseURL: config.apiUrl
});

export function setToken(token) {
  api = axios.create({
    baseURL: config.apiUrl,
    headers: { token }
  });
}

// Static tokens
export let tokens = {
  dc: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjb3Vyc2VTaWQiOiJDVWJkNjg1OWIxZTg5MTQ5MzBiYTRkMzc4NGQ5MjlkODA0IiwiaW5zdGFsbFNpZCI6IkFJNTdkMDZjZjNhNWM0NDE4MWFiNWYxNDFiZjQ2ZTdhYzQiLCJhcHBsaWNhdGlvblNpZCI6IkFQNjgwMGI5ZTJlMTc5NGRiYmJmNmFkODY2MTk0ODIxZDMiLCJkZXBsb3ltZW50U2lkIjoiQUQxZTkwODIzZGViODE0OTIzYjU4NDc3MWYwMWFjOTRkMSIsImlkIjoiNzlhZjEyNDAtYThiZS0xMWU5LWFlOTktMTFlODQwNDMyMDMwIiwiY3JlYXRlX3RpbWUiOjE1NjMzODcxNjUwMjgsInRpbWVvdXQiOjB9._VD71jpO1cs4nYR-3U0qUsMCOhVzYT-K_ovHz0agr8o'
}

// Production tokens
if(process.env.REACT_APP_ENVIRONMENT === 'production') {
  tokens.dc = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjb3Vyc2VTaWQiOiJDVWJkNjg1OWIxZTg5MTQ5MzBiYTRkMzc4NGQ5MjlkODA0IiwiaW5zdGFsbFNpZCI6IkFJNTdkMDZjZjNhNWM0NDE4MWFiNWYxNDFiZjQ2ZTdhYzQiLCJhcHBsaWNhdGlvblNpZCI6IkFQNjgwMGI5ZTJlMTc5NGRiYmJmNmFkODY2MTk0ODIxZDMiLCJkZXBsb3ltZW50U2lkIjoiQUQxZTkwODIzZGViODE0OTIzYjU4NDc3MWYwMWFjOTRkMSIsImlkIjoiNzlhZjEyNDAtYThiZS0xMWU5LWFlOTktMTFlODQwNDMyMDMwIiwiY3JlYXRlX3RpbWUiOjE1NjMzODcxNjUwMjgsInRpbWVvdXQiOjB9._VD71jpO1cs4nYR-3U0qUsMCOhVzYT-K_ovHz0agr8o';
}
