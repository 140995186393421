/**
 * Renders a view that allows users to look up their appointment details
 */

import React from 'react';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import { Link } from 'react-router-dom';

// Config
import { setToken } from '../../../config'

// Translation
import translate from '../../translate/Translate';

// Helpers
import {getParam, getUrlSegment, openExamDirect} from '../../../helpers/helpers';

// Dependencies
import { addMinutes, differenceInMinutes, parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

// Components
import Select from '../../common/Select';
import * as icons from '../../ui/Icons'
import Address from '../../common/Address';

const appointmentDetails = observer(class appointmentDetails extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      canStart: false,
      language: null,
      render: false,
      sessionState: 'onTime',
      timerId: null,
      examUrl: '#',
      within24Hours: false,
      within24HourTimer: null
    };
  }

  componentDidMount() {
    const { AppStore, ExamStore, ScheduleStore } = this.props.store;
    const examSid = getParam('examSid');
    const sessionSid = getParam('sessionSid');
    const cancelSession = getParam('cancelSession')

    AppStore.getCurrentTime();

    const { installSid } = AppStore.userData;
    openExamDirect(installSid, examSid, sessionSid)
        .then(examUrl => {
          this.setState({ examUrl });
        })

    // Wait until exams have been retrieved before getting session
    const callback = () => {
      ScheduleStore.getSession(examSid, sessionSid, this.checkTime);
      if(cancelSession) this.initCancel()
    }

    ExamStore.listExams(null, callback);
  }
  componentWillUnmount() {
    if (this.state.timerId) {
      clearTimeout(this.state.timerId);
    }
    if (this.state.within24HourTimer) {
      clearTimeout(this.state.within24HourTimer);
    }
  }

  initCancel = () => {
    const { translation } = this.props;
    const { AppStore } = this.props.store;
    const msg = translation.cancel_msg;

    AppStore.dialogSubmit = this.handleCancel;

    AppStore.showDialog(msg, 'prompt');
  }

  handleCancel = (dialogState) => {
    const { history, location, translation } = this.props;
    const { AppStore, ScheduleStore } = this.props.store;

    const data = {
      cancellationMemo: dialogState.option
    };

    const callback = () => {
      history.push(`/${getUrlSegment(1, location.pathname)}`);
      AppStore.toast = translation.cancel_success;
    }

    AppStore.closeDialog();
    ScheduleStore.cancelSession(data, callback);
  }

  checkTime = () => {
    const { AppStore, ScheduleStore } = this.props.store;
    const { examSession } = ScheduleStore;
    let timerId;
    let within24HourTimer;

    const now = parseISO(AppStore.currentTime);
    const sessionTime = parseISO(examSession.appointmentDate);
    const timeToSession = differenceInMinutes(sessionTime, now);
    const sessionEnd = addMinutes(parseISO(examSession.appointmentDate), examSession.examConfiguration.duration + 30)
    const timeToSessionEnd = differenceInMinutes(sessionEnd, now);
    const sessionMissed = addMinutes(parseISO(examSession.appointmentDate), 30);
    const timeToSessionMissed = differenceInMinutes(sessionMissed, now);
    const millisecondsTo24HoursBefore = sessionTime - 86400000 - now;
    if (millisecondsTo24HoursBefore <= 0) {
      this.setState({
        within24Hours: true
      });
    } else {
      // The session is over a day away, so we will set a timeout to flip this state to not show the cancel or reschedule
      // button if the student stays on the page.
      within24HourTimer = setTimeout(() => {
        this.setState({
          within24Hours: true
        });
      }, millisecondsTo24HoursBefore);
    }

    if(timeToSessionMissed > 0 && timeToSession <= 0) {
      timerId = setTimeout(() => {
        this.setState({
          canStart: false,
          sessionState: 'missed'
        })
      }, (timeToSessionMissed * 60 * 1000))
    }

    this.setState({ timerId, within24HourTimer });

    ScheduleStore.schedulingModule = examSession.schedulingModule;
    ScheduleStore.workflowModule = examSession.location.workflowModule;

    if(timeToSessionEnd <= 0) {
      this.setState({
        sessionState: 'expired'
      });
    } else if(timeToSession > 1) {
      this.setState({
        sessionState: 'early'
      });
    } else if(timeToSession <= 0 && timeToSessionMissed >= 0){
      this.setState({
        sessionState: 'onTime'
      })
    }

    // If the session is not in progress
    // and the sessionMissed time is in the past
    if(examSession.status.toLowerCase() === 'scheduled' && timeToSessionMissed <= 0) {
      this.setState({
        sessionState: 'missed'
      });
    }

    this.getToken();
  }

  /**
   * Get a temporary user token based on the session’s data
   * and store it for use with API calls and hybrid start
   *
   * @function getToken
   * @async
   */
  getToken = async () => {
    const { AppStore, ScheduleStore } = this.props.store;
    const { installSid, userSid } = ScheduleStore.examSession;

    const userData = {
      appInstallSid: installSid,
      userSid
    };

    const token = await ScheduleStore.getUserToken(userData);

    if (token) {
      action(() => {
        AppStore.token = token;
      })();

      setToken(token);

      sessionStorage.setItem('token', token);
    }

    this.setState({
      render: true
    });
  }


  render() {
    const { remote, translation } = this.props;
    const { AppStore, DomainStore, ScheduleStore } = this.props.store;
    const { timeZone, languagesList } = AppStore;
    const { examSession } = ScheduleStore;
    const { sessionState, within24Hours, examUrl } = this.state;
    const isOnlineExam = examSession && examSession.location && examSession.location.workflowModule !== "in-person";

    if(!this.state.render) {
      return null;
    }

    if(examSession) {
      const formattedDate = format(
        utcToZonedTime(parseISO(examSession.appointmentDate), timeZone),
        'M/d/yyyy'
      );

      const formattedTime = format(
        utcToZonedTime(parseISO(examSession.appointmentDate), timeZone),
        'h:mm aaaa zzz'
      );

      return (
        <main className="page wrap">
          <article className="box box--md box--solo">
            <h1 className="box__title">{translation.heading}</h1>
            {isOnlineExam && (
              <React.Fragment>
                <span className="box__subheading">
                  {translation.appointment_date
                    .replace("%time%", formattedTime)
                    .replace("%date%", formattedDate)}
                </span>

                {sessionState === "early" && (
                  <div>
                    <p>{translation.earlyBody}</p>
                    <p style={{ marginBottom: 0 }}>
                      <a
                        href={`${examUrl}&systemCheck=true`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {translation.test_computer}
                      </a>
                    </p>
                    <p>
                      {navigator.userAgent.includes("Macintosh") && (
                        <a
                          href="https://smarterproctoring-utility-app-builds.s3.amazonaws.com/releases/mac/latest/SmarterServicesUtility_latest_Setup.dmg"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {translation.ssUtility}
                        </a>
                      )}

                      {navigator.userAgent.includes("Windows") && (
                        <a
                          href="https://smarterproctoring-utility-app-builds.s3.amazonaws.com/releases/windows/latest/SmarterServicesUtility_latest_Setup.exe"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {translation.ssUtility}
                        </a>
                      )}
                    </p>
                  </div>
                )}

                {(sessionState === "missed" || sessionState === "expired") && (
                  <div>
                    <strong className="note__title">
                      {translation.session_status[sessionState].heading}
                    </strong>

                    <p>
                      {translation.session_status[sessionState].body
                        .replace("%date%", formattedDate)
                        .replace("%time%", formattedTime)}
                    </p>
                  </div>
                )}

                {sessionState === "onTime" && (
                  <React.Fragment>
                    <p>{translation.earlyBody}</p>

                    <div className="form--stack">
                      <Select
                        className="language-select"
                        defaultValue={this.state.language}
                        label={translation.language.label}
                        placeholder={translation.language.placeholder}
                        onChange={(e) => {
                          const urlData = {
                            examSid: getParam("examSid"),
                            installSid: AppStore.userData.installSid,
                            sessionSid: getParam("sessionSid"),
                          };

                          const language = e.target.value;

                          this.setState({ language });

                          const callback = () => {
                            this.setState({ canStart: true });
                          };

                          if (language) {
                            ScheduleStore.updateSessionLanguage(
                              urlData,
                              language,
                              callback
                            );
                          }
                        }}
                        options={languagesList}
                      />

                      <a
                        className={`btn${
                          !this.state.canStart ? " btn--disabled" : ""
                        }`}
                        disabled={!this.state.canStart}
                        href={
                          !this.state.canStart
                            ? "#"
                            : this.state.examUrl
                        }
                      >
                        {translation.start_exam}
                        <icons.chevronRight />
                      </a>
                    </div>

                    <p style={{ marginBottom: 0 }}>
                      <a
                        href={`${examUrl}&systemCheck=true`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {translation.test_computer}
                      </a>
                    </p>

                    <p>
                      {navigator.userAgent.includes("Macintosh") && (
                        <a
                          href="https://smarterproctoring-utility-app-builds.s3.amazonaws.com/releases/mac/latest/SmarterServicesUtility_latest_Setup.dmg"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {translation.ssUtility}
                        </a>
                      )}

                      {navigator.userAgent.includes("Windows") && (
                        <a
                          href="https://smarterproctoring-utility-app-builds.s3.amazonaws.com/releases/windows/latest/SmarterServicesUtility_latest_Setup.exe"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {translation.ssUtility}
                        </a>
                      )}
                    </p>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}

            {!isOnlineExam &&
              <React.Fragment>
                <span className="box__subheading">
                  {translation.appointment_date
                    .replace("%time%", formattedTime)
                    .replace("%date%", formattedDate)}
                </span>
                {sessionState === "onTime" &&
                  <div className="box__address">
                  <b>{examSession.location.accountName}</b>
                  <Address
                    location={examSession.location}
                  />
                </div>
                }
                {sessionState === "onTime" &&
                  <div className="margin__top">
                    <p>{translation.in_person}</p>
                    <em>{translation.in_person_b}</em>
                  </div>
                }
                {(sessionState === "missed" || sessionState === "expired") && (
                  <div>
                    <strong className="note__title">
                      {translation.session_status[sessionState].heading}
                    </strong>

                    <p>
                      {translation.session_status[sessionState].body
                        .replace("%date%", formattedDate)
                        .replace("%time%", formattedTime)}
                    </p>
                  </div>
                )}
              </React.Fragment>
            }

            {sessionState !== "missed" && sessionState !== "expired" && !within24Hours && (
              <div className="box__action">
                <Link
                  className={`btn${remote ? " btn--outline" : ""}`}
                  to={`/${DomainStore.domain}/reschedule/${examSession.examSid}/time?session=${examSession.sid}`}
                >
                  {translation.reschedule}
                </Link>

                <button
                  className="btn btn--outline btn--negative"
                  onClick={this.initCancel}
                >
                  {translation.cancel}
                </button>
              </div>
            )}
          </article>
        </main>
      );
      } else {
        return (
          <main className="page wrap">
            <div className="box box--sm box--solo">
              <h1 className="box__title">
                {translation.no_results.heading}
              </h1>

              <p>{translation.no_results.body}</p>
            </div>
          </main>
        )
      }
    }
  }
)

export default translate('appointmentDetails')(appointmentDetails);