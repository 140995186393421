import { action, observable, toJS } from 'mobx';
import { api } from '../config';

// AppStore (used for loading, error throwing, etc.)
import AppStore from './AppStore';
import ScheduleStore from './ScheduleStore';

let obx = observable({
  /*-------------------------------------------
    Proctor groups
  -------------------------------------------*/
  proctorGroups: [],
  vtcProctorGroup: null,
  inPersonProctorGroup: null,

  /**
   * listProctorGroups - Gets proctor groups for an install
   * @param {boolean} firstOnly - Only stores the first result
   * @param {function} callback
   */
  listProctorGroups: action(function(firstOnly, callback){
    AppStore.startLoading('listProctorGroups');

    const { installSid } = AppStore.userData;

    api.get(`/installs/${installSid}/proctorGroup`)
      .then(response => {
        if(firstOnly) {
          obx.proctorGroups = [response.data.results[0]];
        } else {
          obx.proctorGroups = response.data.results;
        }

        // classify proctor groups
        for (const proctorGroup of response.data.results) {
            if (proctorGroup.workflowModule === 'in-person') {
                obx.inPersonProctorGroup = proctorGroup;
            } else if (proctorGroup.workflowModule === 'hybrid-scheduling' || proctorGroup.workflowModule === 'hybrid-tiered-scheduling') {
                obx.vtcProctorGroup = proctorGroup;
            }
        }

        AppStore.finishLoading('listProctorGroups');

        if(callback) {
          callback();
        }
      })
      .catch(error => {
        AppStore.throwError(error);
      });
  }),

  /*-------------------------------------------
    Proctor search
  -------------------------------------------*/
  locations: [],

  /**
   * proctorSearch
   * @param {object} data
   * @param {function} callback
   */
  proctorSearch: action((data, callback) => {
    AppStore.startLoading('proctorSearch');

    const { installSid } = AppStore.userData;
    const proctorGroupSid = toJS(obx.inPersonProctorGroup.sid);
    const { zipCode } = data;

    api.get(`/installs/${installSid}/proctors/search?proctorGroupSid=${proctorGroupSid}&zipCode=${zipCode}`)
      .then(response => {
        // For now, we are hardcoding a price
        response.data.results.forEach(result => {
          result.fees.userTotal = 39.95;
        });

        obx.locations = response.data.results;

        if(response.data.results && response.data.results.length > 0) {
          obx.proctorsNextAvailability(callback);
        } else if(callback) {
          callback();
        }

        AppStore.finishLoading('proctorSearch');
      })
      .catch(error => {
        AppStore.throwError(error);
      });
  }),

  /**
   * proctorsNextAvailability - Gets the next availability for each proctor location
   * @param {function} callback
   */
  proctorsNextAvailability: action(callback => {
    const { installSid } = AppStore.userData;
    let url = `/installs/${installSid}/proctors/getNextAvailableTime?&duration=${ScheduleStore.exam.configuration.duration}`;

    AppStore.startLoading(`proctorsNextAvailability`);

    obx.locations.forEach(proctor => {
      url += `&proctorLocationSids=${proctor.location.sid}`;
    });

    api.get(url)
      .then(response => {
        if(response.data) {
          Object.keys(response.data).forEach(locationSid => {
            obx.locations.some(proctor => {
              if(proctor.location.sid === locationSid) {
                proctor.location.nextAvailability = response.data[locationSid];
                return true;
              }

              return false;
            });
          });
        }

        AppStore.finishLoading(`proctorsNextAvailability`);

        if(callback) {
          callback();
        }
      })
      .catch(error => {
        AppStore.throwError(error);
      });
  }),

  /**
   * provisionOnlineSession
   * @param {object} data
   * @param {function} callback
   */
  provisionOnlineSession: action(function(data, callback, window) {
    AppStore.startLoading('provisionOnlineSession');

    const { installSid } = AppStore.userData;

    const { examSid, sessionSid, status } = data;
    const body = {
      status
    }

    api.patch(`/installs/${installSid}/exams/${examSid}/sessions/${sessionSid}`, body)
    .then(response => {
      if(callback) {
        callback(response.data.URL);
      }

      AppStore.finishLoading('provisionOnlineSession');
    })
    .catch(error => {
      AppStore.throwError(error);

      if(window) {
        window.close();
      }
    });
  })
});

export default obx;
